import React from 'react';
import firebase from '../firebase'
import {GOOGLE_API_KEY} from '../config';

class FindLocation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      latitude: null,
      longitude: null,
      senderAltitude: null,
      receiverOriginalAltitude: null,
      altitudeDifference: null,
      distanceToSender: null,
      distanceString: null
    };

    this.setLocationFromFirebase = this.setLocationFromFirebase.bind(this);
    this.fetchCoordinates = this.fetchCoordinates.bind(this);
    this.setCurrentPosition = this.setCurrentPosition.bind(this);
    //this.getLocation = this.getLocation.bind(this);
    //this.getDistance = this.getDistance.bind(this);
    //this.updateDistance = this.updateDistance.bind(this);
    //this.getReceiverAltitude = this.getReceiverAltitude.bind(this);
  }

  /*
  getLocation() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(this.getReceiverAltitude, this.handleLocationError);
    } else {
      alert("Geolocation is not supported by this browser.");
    }
  }

  getReceiverAltitude(position){
    console.log("altitude "+ position.coords.altitude);
    this.setState({
      receiverOriginalAltitude: position.coords.altitude
    })
  }
  */

  setCurrentPosition(options = {}) {
    console.log("starting from inside get current position");
    return new Promise((resolve, reject) => {
        navigator.geolocation.getCurrentPosition(resolve, reject, options);
    });
  }

  handleLocationError(error){
    switch(error.code) {
      case error.PERMISSION_DENIED:
        alert("User denied the request for Geolocation.")
        break;
      case error.POSITION_UNAVAILABLE:
        alert("Location information is unavailable.")
        break;
      case error.TIMEOUT:
        alert("The request to get user location timed out.")
        break;
      case error.UNKNOWN_ERROR:
        alert("An unknown error occurred.")
        break;
      default:
        alert("An unknown error occured.")
    }
  }

  fetchCoordinates = async () => {
    if (navigator.geolocation) {
        try {
            console.log("calling get current position");
            const { coords } = await this.setCurrentPosition();
            console.log("got current position");
            const { latitude, longitude, altitude } = coords;
            console.log("latitude: " + latitude);
            console.log("longitude: " + longitude);
            console.log("altitude: " + altitude);
            // Handle coordinates
            this.setState({
                receiverOriginalAltitude: altitude
            })
        } catch (error) {
            // Handle error
            console.error(error);
        }
    } else {
        alert("Geolocation is not supported by this browser.");
    } 
    console.log('coordinates fetched and altitude saved');
  };

  setLocationFromFirebase = async () =>  {
    console.log("starting location setting process");
    const {location_id} = this.props.match.params;

    await this.fetchCoordinates();
    console.log("fetched coordinates");
    
    if (!location_id) return;

    //connect to firebase and get data
    const db = firebase.firestore();

    await db.collection('test_locations')
      .doc(location_id)
      .get()
      .then(doc => {
        if(doc.exists){
          this.setState({
            latitude: doc.data()._latitude,
            longitude: doc.data()._longitude,
            senderAltitude: doc.data()._altitude
        });
        // console.log(`latitude: ${this.state.latitude}; longitude: ${this.state.longitude}; altitude: ${this.state.senderAltitude};`);

      }
      // console.log(this.state.latitude);
      // console.log("finished location setting process");
      /*
      //get the scene and camera to mount new objects onto
      let scene = document.querySelector('a-scene');
      let mainCamera = document.getElementById('main-camera');

      //add a location pointer to the scene
      let locationPointer = document.createElement('a-entity');
      locationPointer.setAttribute('id','location-pointer');
      locationPointer.setAttribute('gps-entity-place', `latitude: ${this.state.latitude}; longitude: ${this.state.longitude};`);
      locationPointer.setAttribute('geometry', "primitive: cone; radiusBottom: 0.1; radiusTop: 1; height: 2;");
      locationPointer.setAttribute('material', "color: red; side: double");

      locationPointer.addEventListener('loaded', () => {
          window.dispatchEvent(new CustomEvent('location-pointer-loaded'))
      });

      scene.appendChild(locationPointer);
      console.log("appended location pointer");

      //add a location pillar to the scene
      let locationPillar = document.createElement('a-entity');
      locationPillar.setAttribute('id','location-pillar');
      locationPillar.setAttribute('gps-entity-place', `latitude: ${this.state.latitude}; longitude: ${this.state.longitude};`);
      locationPillar.setAttribute('geometry', "primitive: cylinder; height: 15; radius: 2");
      locationPillar.setAttribute('material', "color: yellow; transparent: true; opacity: 0.5");

      locationPillar.addEventListener('loaded', () => {
          window.dispatchEvent(new CustomEvent('location-pillar-loaded'))
      });

      scene.appendChild(locationPillar);
      console.log("appended location pillar");

      //add a arrow pointer to the mainCamera
      let arrow = document.createElement('a-entity');
      arrow.setAttribute('id','arrow');
      arrow.setAttribute('position', "0 0 -1");
      arrow.setAttribute('geometry', "primitive: box; width: 0.1; height: 0.1; depth: 0.3");
      arrow.setAttribute('material', "color: green");
      arrow.setAttribute('look-at', "#location-pointer");

      locationPillar.addEventListener('loaded', () => {
          window.dispatchEvent(new CustomEvent('arrow-loaded'))
      });

      mainCamera.appendChild(arrow);
      console.log("appended arrow");
      console.log("finished location setting process");
      //this.setState({ state: this.state });
      */
    })
    .then(doc => {
      if(this.state.latitude){
        console.log("starting entity location setting");
        let entity1 = document.getElementById('location-pointer');
        //let entity2 = document.getElementById('location-pillar');
        // console.log(entity1);
        //console.log(entity2);
        //console.log("this in comp did mount" + this);
        // console.log(this.state.latitude);
        entity1.setAttribute('gps-entity-place', `latitude: ${this.state.latitude}; longitude: ${this.state.longitude};`);
        //entity2.setAttribute('gps-entity-place', `latitude: ${this.state.latitude}; longitude: ${this.state.longitude};`);
        // console.log(entity1.getAttribute('gps-entity-place'));
        //console.log(entity2.getAttribute('gps-entity-place'));
        // console.log("added locations to entities")
        //entity1.setAttribute('position', '0 10 0');
        //console.log("added altitude y-pos to entity");
        //this.setState({ state: this.state });
      };
    })
    .then(doc =>{
      console.log("starting altitude setting");
      if(this.state.senderAltitude && this.state.receiverOriginalAltitude){
        var initialYValue = this.state.senderAltitude - this.state.receiverOriginalAltitude;
        let entity1 = document.getElementById('location-pointer');
        //var xPos = entity1.getAttribute('position').x;
        //var zPos = entity1.getAttribute('position').z;
        //entity1.setAttribute('position', `${xPos} ${initialYValue} ${zPos}`);
        entity1.setAttribute('position', `0 ${initialYValue} 0`);
        //console.log("position after setting y:"+entity1.getAttribute('position'));
        //entity1.setAttribute('gps-entity-place', `latitude: ${this.state.latitude}; longitude: ${this.state.longitude};`);
        //console.log("position after setting entityplace 2nd time:"+entity1.getAttribute('position'));
        //let camera = document.getElementById('main-camera');
        //camera.setAttribute('simulateAltitude', -initialYValue);
        //alert("Initial y position set to: "+ initialYValue);
      };
      //this.setState({state: this.state});
    })
  }

  componentDidMount(){
      console.log("starting componentDidMount")
      
      if (this.state.latitude){
        console.log("latitude exists, setting gps entity again")
      //this.setLocationFromFirebase();
      //this.setState({ state: this.state });
      //set the geolocation of the shape
        let entity1 = document.getElementById('location-pointer');
        //let entity2 = document.getElementById('location-pillar');
        // console.log(entity1);
        // console.log(entity2);
        //console.log("this in comp did mount" + this);
        // console.log(this.state.latitude);
        entity1.setAttribute('gps-entity-place', `latitude: ${this.state.latitude}; longitude: ${this.state.longitude};`);
        //entity2.setAttribute('gps-entity-place', `latitude: ${this.state.latitude}; longitude: ${this.state.longitude};`);
        // console.log(entity1.getAttribute('gps-entity-place'));
        // console.log(entity2.getAttribute('gps-entity-place'));
        // console.log("added locations to entities")
        //this.setState({ state: this.state }); 
      } else {
        console.log("calling set location from firebase");
        this.setLocationFromFirebase();
      }
      //set the altitude of the shape to the difference between the sender and receiver
      //var altitudeDifference = 0;
      //if(this.senderAltitude && this.receiverOriginalAltitude){
      //    altitudeDifference = this.senderAltitude - this.receiverOriginalAltitude;
      //}
      //entity.setAttribute('position', {x: 0, y: altitudeDifference, z: 0});
      //console.log(entity1.getAttribute('position'));
      /*var distance = document.querySelector('[gps-entity-place]').getAttribute('distance');
      if (distance !== null){
        console.log("distance to sender: "+distance);
        this.setState({
          distanceToSender: distance
        });
      }*/
      //console.log("finished distance setting process");

    //create a timer to tick async every second and update distance to sender location
    /*this.updateTimer = setInterval(
      () => this.updateDistance(),
      1000
    );*/
  }

  /*componentDidUpdate(){
      if (document.querySelector('[gps-entity-place]') !== null){
        var distance = document.querySelector('[gps-entity-place]').getAttribute('distance');
        if (distance !== null){
          console.log("distance to sender: "+distance);
          this.setState({
            distanceToSender: distance
          });
        }
      }
  }

  getDistance(){
      if (document.querySelector('[gps-entity-place]') !== null){
        var distance = document.querySelector('[gps-entity-place]').getAttribute('distance');
        if (distance !== null){
          console.log("distance to sender: "+distance);
          var distanceString = distance+'m away';
          document.getElementById("#distance-label").setAttribute('value', distanceString);
          return distance;
        }
      }
  }*/

  /*updateDistance(){
    if (document.querySelector('[gps-entity-place]').getAttribute('distanceMsg') !== null){
      this.setState({
        distanceToSender: document.querySelector('[gps-entity-place]').getAttribute('distanceMsg'),
        distanceString: ""+this.state.distanceToSender+"m away"
      });
      console.log(this.state.distanceToSender);
      document.getElementById("#distance-label").setAttribute('value', this.state.distanceString);
      console.log(this.state.distanceString);
    }
  }*/

  componentWillUnmount() {
    let mainCamera = document.getElementById('main-camera');
    let loadingPlane = document.getElementById('loading-plane');
    let loadingLabel = document.getElementById('loading-label');
    
    let arrowPointer = document.getElementById('arrow-pointer');
    let locationPointer = document.getElementById('location-pointer');

    let scene = document.querySelector('a-scene');

    //remove entities from scene
    arrowPointer.parentNode.removeChild(arrowPointer);
    locationPointer.parentNode.removeChild(locationPointer);
    if (loadingLabel) {
      loadingLabel.parentNode.removeChild(loadingLabel);
    }
    if (loadingPlane){
      loadingPlane.parentNode.removeChild(loadingPlane);
    }
    mainCamera.parentNode.removeChild(mainCamera);

    let video = document.getElementById('arjs-video');
    video.parentNode.removeChild(video);
    //then remove scene
    //scene.parentNode.removeChild(scene);
    window.location.reload();
  }


  render() {
    console.log("started render");
    // console.log(this.state.latitude);
    
    if (!this.state.latitude && !this.state.longitude){
        return (
          <a-scene
          vr-mode-ui="enabled: false"
          
          gps-camera-debug
          arjs="sourceType: webcam; debugUIEnabled: false; sourceWidth: 600; sourceHeight: 400; displayWidth: 600, displayHeight: 400; canvasWidth: 600; canvasHeight: 400;"
          >
            <a-camera
              id = "main-camera"
              look-controls-enabled='false'
              arjs-look-controls='smoothingFactor: 0.1'
              gps-camera ='gpsMinDistance: 5; gpsTimeInterval: 5000'
              rotation-reader
            >
              <a-entity
                id = "loading-plane"
                geometry="primitive: plane; height: 0.1; width: 0.1"
                position="0 -0.3 -1"
                material="color: gray; opacity: 0.5"
              >
                  <a-text
                    id = "loading-label"
                    position="0 -0.3 -1"
                    value="Loading..."
                    rotation="0 0 0"
                    scale= "1"
                    width = "0.5"
                    wrapCount = "10"
                    align="center"
                  >
                  </a-text>
              </a-entity>
            </a-camera>
            {console.log("rendered location pointer")}
            {console.log("finished render loop")}
          </a-scene>
        )
    } else {
      console.log("rendering with location");
      return (
        
          <a-scene
          vr-mode-ui="enabled: false"
          
          gps-camera-debug
          arjs="sourceType: webcam; debugUIEnabled: false; sourceWidth: 600; sourceHeight: 400; displayWidth: 600, displayHeight: 400; canvasWidth: 600; canvasHeight: 400;"
          >
          <a-assets>
             <a-asset-item id="pointer-obj" src="/models/pointer.obj"></a-asset-item>
             <a-asset-item id="pointer-mtl" src="/models/pointer.mtl"></a-asset-item>
             <a-asset-item id="arrow-gltf" src="/models/model.gltf"></a-asset-item>
             <a-asset-item id="map-pointer-obj" src="/models/map-pointer.obj"></a-asset-item>
             <a-asset-item id="map-pointer-mtl" src="/models/map-pointer.mtl"></a-asset-item>
          </a-assets>
          <a-entity
              id= "location-pointer"
              //gltf-model="#map-pointer-gltf"
              //obj-model='obj: url(models/map-pointer.obj); mtl: url(models/map-pointer.mtl)'
              obj-model="obj: #map-pointer-obj; mtl: #map-pointer-mtl"
              scale= "3 3 3"
              look-at="#main-camera"
              //gps-entity-place={`latitude: ${this.state.latitude}; longitude: ${this.state.longitude};`}              
              //gps-entity-place="latitude: 37.3687643668858; longitude: -122.06355101711125;"
          >
          </a-entity>
          {console.log("rendered location pointer")}
          <a-camera
            id = "main-camera"
            look-controls-enabled='false'
            arjs-look-controls='smoothingFactor: 0.1'
            gps-camera ='gpsMinDistance: 5;'
            rotation-reader
          >
            {/*draw a rectangle pointing to (looking at) the location pointer*/}
              <a-entity
                id = "arrow-pointer"
                //obj-model="obj: #pointer-obj; mtl: #pointer-mtl"
                gltf-model="src: url(/models/scene.gltf)"
                //obj-model='obj: url(models/pointer.obj); mtl: url(models/pointer.mtl)'
                //gltf-model="#pointer-gltf"
                position="0 -0.25 -1"
                scale="0.15 0.15 0.15"
                look-at="#location-pointer">
              </a-entity>
            </a-camera>
            {/*<a-entity
            id = "location-pillar"
            geometry="primitive: cylinder; height: 15; radius: 2"
            material="color: yellow; transparent: true; opacity: 0.5"
            >
            </a-entity>*/}
            {console.log("finished render loop")}
          </a-scene>
        
      );
    }
  }
}

export default FindLocation;