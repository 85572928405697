import React, { Component } from 'react';
// import { render } from "react-dom";

// import from React Router for page routing
import { BrowserRouter, Route, Router, Switch } from 'react-router-dom';

import './App.css';

//import necessities for Google Maps API
//import { Map, GoogleApiWrapper, Marker  } from 'google-maps-react';
import {GOOGLE_API_KEY} from './config';
//import { GeoPoint } from '@google-cloud/firestore';

//import components for the pages of the site
import Home from './components/Home';
import About from './components/pages/About/About';
import Support from './components/pages/Support/Support';
import Find from './components/Find';
import FindLocation from './components/FindLocation';
import Error from './components/Error';
import ARTest from './components/ARTest';
import HomeHero from './components/pages/HomePage/HomeHero';
import Navbar from './components/Navbar';
import Footer from './components/pages/Footer/Footer';
import PrivacyPage from './components/pages/Privacy/Privacy';
import ContactPage from './components/pages/Contact/ContactPage';

class App extends React.Component {
  render() {
    return (
      <>
        <Navbar/>
        <Switch>
          <Route path="/" component={HomeHero} exact/>
          <Route path="/about" component={About} exact/>
          <Route path="/support" component={Support} exact/>
          <Route path="/privacy" component={PrivacyPage} exact/>
          <Route path="/contact" component={ContactPage} exact/>
          <Route path='/find/:location_id' component = {FindLocation} exact/>
          <Route component={Error}/>
        </Switch>
        <Footer/>
      </>
    );
  }
}

export default App;
