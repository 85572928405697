import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import ReactDOM from 'react-dom';

import App from './App';
import Error from './components/Error';

import * as serviceWorker from './serviceWorker';
// import * as firebase from 'firebase';

// firebase.initializeApp(config);

ReactDOM.render(
    <BrowserRouter>
      <App />
    </BrowserRouter>,
  document.getElementById('app-body')
);

/*
if (window.location.pathname === '/testlocation.html') {
    ReactDOM.render(
        <Error />,
        document.getElementById('location-body')
    );
} else {
    ReactDOM.render(
        <BrowserRouter>
          <App />
        </BrowserRouter>,
      document.getElementById('app-body')
    );
}*/

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
