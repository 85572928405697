  export const aboutObj = {
    lightBg: true,
    lightText: false,
    lightTextDesc: false,
    topLine: 'HOW IT WORKS',
    headline: 'Use Geolocation and Web AR to help friends find you',
    description:
      "Location FindAR uses geolocation and augmented reality to generate a web link with an AR marker pointing to your location. You can then share that link how you wish.",
    buttonLabel: 'Try it now',
    imgStart: '',
    img: 'images/step_3_icon_placeholder.svg',
    alt: 'Location FindAR creates a link with an AR marker to your location'
  };