// Your web app's Firebase configuration
import firebase from 'firebase'

const firebaseConfig = {
  apiKey: "AIzaSyAVz1BXC6SQoDpxIHed7MenUAVuS5Syb_s",
  authDomain: "location-findar.firebaseapp.com",
  databaseURL: "https://location-findar.firebaseio.com",
  projectId: "location-findar",
  storageBucket: "location-findar.appspot.com",
  messagingSenderId: "864934069048",
  appId: "1:864934069048:web:00974a6241e71090bcb7cd",
  measurementId: "G-YMEFRYGNSK"
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);
firebase.analytics();

export default firebase;
