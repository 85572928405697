import React from 'react'
import {Button} from './Button'
import {Link} from 'react-router-dom'
import './SupportSection.css'
import { HashLink } from 'react-router-hash-link';

export default function HeroSection({
    id,
    lightBg,
    topLine,
    lightText,
    lightTextDesc,
    headline,
    description,
    buttonLabel,
    img,
    alt,
    imgStart
}) {
    return (
        <>
            <div 
                id= {id ? id : ''}
                className= {lightBg ? 'home__hero-section' : 'home__hero-section darkBg'}>
                <div className="container">
                    <div className="row home__hero-row"
                        style={{display: 'flex'}}
                    >
                        <div className="col">
                            <div className="home__hero-text-wrapper">
                                <div className="top-line">
                                    {topLine}
                                </div>
                                <h1 className={lightText ? 'heading' : 'heading dark'}>{headline}</h1>
                                <p className={lightTextDesc ? 'home__hero-subtitle': 'home__hero-subtitle dark'}> 
                                    1. Location FindAR works best on Safari on iOS and on Chrome on Android. Other browsers have limited support for AR.js, the web AR library we use. 
                                    <br/><br/> 2. Location accuracy depends on GPS signal. For best results, use outside, such as at a park :) 
                                    <br/><br/> 3. If you have trouble with the AR markers, please refresh the web page. 
                                    <br/><br/>If you have any questions, or would like further support, contact us below!
                                </p>
                                <a href="mailto:support@locationfindar.com">
                                    <Button buttonSize='btn--wide' buttonColor='blue'>{buttonLabel}</Button>
                                </a>  
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
