import React from 'react'
import HeroSection from '../../HeroSection'
import Footer from '../Footer/Footer'
import {aboutObj} from './Data'

function HomeHero() {
  return (
    <div>
      <HeroSection {...aboutObj}/>
    </div>
  );
}

export default HomeHero
