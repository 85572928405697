import React from 'react'
import SupportSection from '../../SupportSection'
import Footer from '../Footer/Footer'
import {supportObj} from './Data'

function SupportPage () {
  return (
    <div>
      <SupportSection {...supportObj}/>
    </div>
  );
}

export default SupportPage
