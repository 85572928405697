import React from 'react'
import HeroSection from '../../HeroSection'
import GetLocationSection from '../../GetLocationSection'
import Footer from '../Footer/Footer'
import {homeObjFour, homeObjOne, homeObjThree, homeObjTwo} from './Data'
import ShareLocationSection from '../../ShareLocationSection'
import SeeARSection from '../../SeeARSection'

class HomeHero extends React.Component {
  constructor(props) {

    super(props);
    this.state = {
      latitude: null,
      longitude: null,
      altitude: null,
      locationURL: 'blank',
      location_id: null
    };

    this.handleUrlChange = this.handleUrlChange.bind(this);    
  }

  handleUrlChange (locationUrl, locationId){
    this.setState({
      locationURL: locationUrl,
      location_id: locationId
    });
    console.log("running handleUrlChange");
    console.log(`home page level URL state: ${locationUrl}, location id: ${locationId}`);
  }

  render(){
    return (
      <div>
        <HeroSection {...homeObjOne}/>
        <GetLocationSection {...homeObjTwo} onLocationSave={this.handleUrlChange}/>
        <ShareLocationSection {...homeObjThree} locationURL = {this.state.locationURL} />
        <SeeARSection {...homeObjFour} locationURL = {this.state.locationURL} />
      </div>
    );
  }
}

export default HomeHero;
