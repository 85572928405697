import React from 'react'
import PrivacyPolicySection from '../../PrivacyPolicySection';
import Footer from '../Footer/Footer'
import {privacyObj} from './Data'

function PrivacyPage () {
  return (
    <div>
      <PrivacyPolicySection {...privacyObj}/>
    </div>
  );
}

export default PrivacyPage
