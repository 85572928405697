export const contactObj = {
    lightBg: true,
    lightText: false,
    lightTextDesc: false,
    topLine: 'GET IN TOUCH',
    headline: 'Contact us with questions, feedback, or to collaborate',
    description:
      "Location FindAR is a passion project from a small team. If you have feedback for ways to make it better, or would like to collaborate, please get in touch!",
    buttonLabel: 'Get in touch',
    imgStart: '',
    img: '',
    alt: ''
  };