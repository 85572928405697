import React from 'react'
import {Button} from './Button'
import {Link} from 'react-router-dom'
import './SupportSection.css'
import { HashLink } from 'react-router-hash-link';

export default function PrivacyPolicySection({
    id,
    lightBg,
    topLine,
    lightText,
    lightTextDesc,
    headline,
    description,
    buttonLabel,
    img,
    alt,
    imgStart
}) {
    return (
        <>
            <div 
                id= {id ? id : ''}
                className= {lightBg ? 'home__hero-section' : 'home__hero-section darkBg'}>
                <div className="container">
                    <div className="row home__hero-row"
                        style={{display: 'flex'}}
                    >
                        <div className="col">
                            <div className="home__hero-text-wrapper">
                                <div className="top-line">
                                    {topLine}
                                </div>
                                <h1 className={lightText ? 'heading' : 'heading dark'}>{headline}</h1>
                                <p className={lightTextDesc ? 'home__hero-subtitle': 'home__hero-subtitle dark'}> 
                                    We do not collect, use, save, or have access to any of your other data through the Location FindAR app.
                                    <br/><br/>
                                    You will be asked to provide access to your location. We save this location temporarily on secure Google servers. The location is saved only for you to share with friends, on your terms. We do not access your location.
                                    <br/><br/>
                                    If you access the Augmented Reality camera, you will be asked to provide access to your camera and movement sensors. This is only to allow the app to draw and update the location markers around you.
                                    <br/><br/>In addition, if you take a screenshot or photo while using the Location FindAR app, it will be saved on your device, in your photo library, and we will have no access to it.
                                    <br/><br/> For more information about Location FindAR or to contact us, please contact us.
                                </p>
                                <a href="mailto:hello@locationfindar.com">
                                    <Button buttonSize='btn--wide' buttonColor='blue'>{buttonLabel}</Button>
                                </a>  
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
