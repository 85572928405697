import React from 'react'
import {Button} from './Button'
import {Link} from 'react-router-dom'
import './ShareLocationSection.css'
import { HashLink } from 'react-router-hash-link';

class ShareLocationSection extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            locationURL: this.props.locationURL
          };
    }

    componentWillReceiveProps(nextProps) {
        this.setState({ locationURL: nextProps.locationURL });  
    }

    shareLocation() {
        if (navigator.share) {
            console.log(`location url in share Location: ${this.state.locationURL}`);
          //navigator.geolocation.getCurrentPosition(this.getCoordinates, this.handleLocationError);
          navigator.share({
            title: "Location FindAR",
            text: "To help find my location, see it in Augmented Reality:",
            url: this.state.locationURL
          })
          .then(() => console.log('Successful share'))
          .catch((error) => console.log('Error sharing', error));
        } else {
          alert("Web Share API is not supported in your browser. Please use the link below, and copy and paste it to share directly.");
        }
    }

    render() {
        return (
            <>
                <div 
                    id= {this.props.id ? this.props.id : ''}
                    className= {this.props.lightBg ? 'home__hero-section' : 'home__hero-section darkBg'}>
                    <div className="container">
                        <div className="row home__hero-row"
                            style={{display: 'flex', flexDirection: this.props.imgStart === 'start' ? 'row-reverse' : 'row'}}
                        >
                            <div className="col">
                                <div className="home__hero-text-wrapper">
                                    <div className="top-line">
                                        {this.props.topLine}
                                    </div>
                                    <h1 className={this.props.lightText ? 'heading' : 'heading dark'}>{this.props.headline}</h1>
                                    <p className={this.props.lightTextDesc ? 'home__hero-subtitle': 'home__hero-subtitle dark'}> {this.props.description}</p>
                                    <HashLink smooth to="/#step3">
                                        <Button onClick={() => {this.shareLocation()}} buttonSize='btn--wide' buttonColor='blue'>{this.props.buttonLabel}</Button> 
                                    </HashLink>
                                </div>
                            </div>
                            <div className ="col">
                                <div className="home__hero-img-wrapper">
                                    <img src={this.props.img} alt={this.props.alt} className="home__share-img"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default ShareLocationSection;