import React from 'react'
import {Button} from './Button'
import {Link, withRouter} from 'react-router-dom'
import './HeroSection.css'
import { HashLink } from 'react-router-hash-link';

//import to support firebase and location query functionality
import firebase from '../firebase';

//import necessities for Google Maps API
import {GOOGLE_API_KEY} from '../config';

class GetLocationSection extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            latitude: null,
            longitude: null,
            altitude: null,
            locationURL: null,
            location_id: null
          };
          this.getLocation = this.getLocation.bind(this);
          this.getCoordinates = this.getCoordinates.bind(this);
          this.handleLocationSave = this.handleLocationSave.bind(this);
          this.getAndSaveLocation = this.getAndSaveLocation.bind(this);
          //this.reverseGeocodeCoordinates = this.reverseGeocodeCoordinates.bind(this);
          this.postLocation = this.postLocation.bind(this);
          this.getCurrentPosition = this.getCurrentPosition.bind(this);
          this.fetchCoordinates = this.fetchCoordinates.bind(this);
          //this.shareLocation = this.shareLocation.bind(this);
    }

    handleLocationSave() {
        console.log(`url: ${this.state.locationURL}, id: ${this.state.location_id}`);
        this.props.onLocationSave(this.state.locationURL, this.state.location_id);
    }

    async getAndSaveLocation(){
        console.log('Getting location');
        /*var locationDict = await this.getLocation();
        const latitude = locationDict["latitude"];
        const longitude = locationDict["latitude"];
        const altitude = locationDict["latitude"];
        console.log(`Latitude: ${latitude}`);*/
        await this.fetchCoordinates();
        console.log(`latitude: ${this.state.latitude}, longitude: ${this.state.longitude}, altitude: ${this.state.altitude}`);
        await this.postLocation();
        console.log('Saving location');
        this.handleLocationSave();
        console.log('Location URL: '+this.state.locationURL);
    }

    getCurrentPosition(options = {}) {
        return new Promise((resolve, reject) => {
            navigator.geolocation.getCurrentPosition(resolve, reject, options);
        });
    }

    fetchCoordinates = async () => {
        if (navigator.geolocation) {
            try {
                const { coords } = await this.getCurrentPosition();
                const { latitude, longitude, altitude } = coords;
                console.log("latitude: " + latitude);
                console.log("longitude: " + longitude);
                console.log("altitude: " + altitude);

                // Handle coordinates
                this.setState({
                    latitude: latitude,
                    longitude: longitude,
                    altitude: altitude
                })
            } catch (error) {
                // Handle error
                console.error(error);
            }
        } else {
            alert("Geolocation is not supported by this browser.");
        } 
    };

    async getLocation() {
        if (navigator.geolocation) {
          const coordinates = navigator.geolocation.getCurrentPosition(this.getCoordinates, this.handleLocationError);
          console.log("coordinates: "+coordinates);
          return {
              "latitude": this.state.latitude,
              "longitude": this.state.longitude,
              "altitude": this.state.altitude 
            };
        } else {
          alert("Geolocation is not supported by this browser.");
        }
    }

    getCoordinates(position){
        console.log(`https://maps.googleapis.com/maps/api/staticmap?center=${this.state.latitude},${this.state.longitude}&zoom=20&size=300x225&sensor=false&markers=color:red%7C${this.state.latitude},${this.state.longitude}&key=${GOOGLE_API_KEY}`);
        console.log("altitude "+ position.coords.altitude);
        this.setState({
          latitude: position.coords.latitude,
          longitude: position.coords.longitude,
          altitude: position.coords.altitude
        })
    }

    handleLocationError(error){
        switch(error.code) {
          case error.PERMISSION_DENIED:
            alert("User denied the request for Geolocation.")
            break;
          case error.POSITION_UNAVAILABLE:
            alert("Location information is unavailable.")
            break;
          case error.TIMEOUT:
            alert("The request to get user location timed out.")
            break;
          case error.UNKNOWN_ERROR:
            alert("An unknown error occurred.")
            break;
          default:
            alert("An unknown error occured.")
        }
    }

    postLocation = async () =>  {
        if (this.state.latitude && this.state.longitude) {
          const db = firebase.firestore();
          await db.collection('test_locations').add({_latitude: this.state.latitude, _longitude: this.state.longitude, _altitude: this.state.altitude})
            .then((snapshot) => {
              console.log(snapshot.id)
              this.setState({
                location_id: snapshot.id,
                locationURL: '/find/'+snapshot.id
              });
            }).then(() => {
                //console.log(`locationURL: ${this.state.locationURL}`);
                //this.handleLocationSave();
            });
        } else {
          alert("Latitude and Longitude not yet detected");
        }
      }
    
      getCoordinates(position){
        console.log(`https://maps.googleapis.com/maps/api/staticmap?center=${this.state.latitude},${this.state.longitude}&zoom=20&size=300x225&sensor=false&markers=color:red%7C${this.state.latitude},${this.state.longitude}&key=${GOOGLE_API_KEY}`);
        console.log("altitude "+ position.coords.altitude);
        this.setState({
          latitude: position.coords.latitude,
          longitude: position.coords.longitude,
          altitude: position.coords.altitude
        })
      }

    render() {
        return (
            <>
                <div 
                    id= {this.props.id ? this.props.id : ''}
                    className= {this.props.lightBg ? 'home__hero-section' : 'home__hero-section darkBg'}>
                    <div className="container">
                        <div className="row home__hero-row"
                            style={{display: 'flex', flexDirection: this.props.imgStart === 'start' ? 'row-reverse' : 'row'}}
                        >
                            <div className="col">
                                <div className="home__hero-text-wrapper">
                                    <div className="top-line">
                                        {this.props.topLine}
                                    </div>
                                    <h1 className={this.props.lightText ? 'heading' : 'heading dark'}>{this.props.headline}</h1>
                                    <p className={this.props.lightTextDesc ? 'home__hero-subtitle': 'home__hero-subtitle dark'}> {this.props.description}</p>
                                    <HashLink smooth to="/#step2">
                                        <Button onClick={this.getAndSaveLocation} buttonSize='btn--wide' buttonColor='blue'>{this.props.buttonLabel}</Button>
                                    </HashLink>
                                </div>
                            </div>
                            <div className ="col">
                                <div className="home__hero-img-wrapper">
                                    {
                                        this.state.latitude && this.state.longitude ?
                                        <img src={`https://maps.googleapis.com/maps/api/staticmap?center=${this.state.latitude},${this.state.longitude}&zoom=18&size=500x400&sensor=false&markers=color:red%7C${this.state.latitude},${this.state.longitude}&key=${GOOGLE_API_KEY}`} alt='' />
                                        :
                                        <img src={this.props.img} alt={this.props.alt} className="home__get-img"/>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }

}

export default GetLocationSection;
/*
export default function GetLocationSection({
    id,
    lightBg,
    topLine,
    lightText,
    lightTextDesc,
    headline,
    description,
    buttonLabel,
    img,
    alt,
    imgStart
}) {
    return (
        <>
            <div 
                id= {id ? id : ''}
                className= {lightBg ? 'home__hero-section' : 'home__hero-section darkBg'}>
                <div className="container">
                    <div className="row home__hero-row"
                        style={{display: 'flex', flexDirection: imgStart === 'start' ? 'row-reverse' : 'row'}}
                    >
                        <div className="col">
                            <div className="home__hero-text-wrapper">
                                <div className="top-line">
                                    {topLine}
                                </div>
                                <h1 className={lightText ? 'heading' : 'heading dark'}>{headline}</h1>
                                <p className={lightTextDesc ? 'home__hero-subtitle': 'home__hero-subtitle dark'}> {description}</p>
                                <HashLink smooth to="/#step1">
                                    <Button buttonSize='btn--wide' buttonColor='blue'>{buttonLabel}</Button>
                                </HashLink>  
                            </div>
                        </div>
                        <div className ="col">
                            <div className="home__hero-img-wrapper">
                                <img src={img} alt={alt} className="home__hero-img"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
*/