import React from 'react';

class ARTest extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      curTime: new Date().toLocaleString(),
      distanceToSender: null
    };
    this.successCallback = this.successCallback.bind(this);
    this.failureCallback = this.failureCallback.bind(this);
  }

    componentDidMount(){
      console.log("starting componentDidMount");
      let entity1 = document.getElementById('location-pointer');
      let entity2 = document.getElementById('location-pillar');
      console.log("got entities");
      entity1.setAttribute('gps-entity-place', "latitude: 37.3687643668858; longitude: -122.06355101711125;");
      entity2.setAttribute('gps-entity-place', "latitude: 37.3687643668858; longitude: -122.06355101711125;");
      console.log("added locations to entities");

      var d;
      var text;
      setTimeout(() => {
        d = document.querySelector('[gps-entity-place]').getAttribute('distanceMsg');
      }, 1000)
      setTimeout(() => {
        text = document.querySelector('#distance-label');
        text.setAttribute('value', d);
      }, 1000)

      setInterval(() => {

        //var d = new Date();
        //console.log(document.querySelector('[gps-entity-place]'));
        //console.log(document.querySelector('[gps-entity-place]').getAttribute('position')['x']);
        //console.log(document.querySelector('[gps-entity-place]').getAttribute('gps-entity-place'));
        //console.log(document.querySelector('#location-pointer').getAttribute('distanceMsg'));
        //var x = document.querySelector('#location-pointer').getAttribute('position')['x'];
        //var y = document.querySelector('#location-pointer').getAttribute('position')['y'];
        //var z = document.querySelector('#location-pointer').getAttribute('position')['z'];
        //var d = Math.sqrt(x^2+y^2+z^2);
        //console.log(d);
        //var d = document.querySelector('[gps-entity-place]').getAttribute('position');

        /*
        promise approach

        const promise = document.querySelector('[gps-entity-place]').getAttribute('distanceMsg');
        promise.then(this.successCallback,this.failureCallback);
        console.log(this.distanceToSender);*/
        d = document.querySelector('[gps-entity-place]').getAttribute('distanceMsg');
        text.setAttribute('value', d);
      }, 1000)
    }

    successCallback(){
      this.setState({
        distanceToSender: document.querySelector('[gps-entity-place]').getAttribute('distanceMsg')
      });
    }

    failureCallback(){
      this.setState({
        distanceToSender: "Loading distance..."
      });
    }

    render() {
      console.log("starting to render");
      return (
        <a-scene
        vr-mode-ui="enabled: false"
        embedded
        gps-camera-debug
        arjs="sourceType: webcam; debugUIEnabled: false;"
        >
          <a-camera
            id = "#main-camera"
            look-controls-enabled='false'
            arjs-look-controls='smoothingFactor: 0.1'
            gps-camera ='gpsMinDistance: 5; gpsTimeInterval: 300000'
            rotation-reader
          >
          {/*draw a rectangle pointing to (looking at) the location pointer*/}
            {console.log("rendered camera")}
            <a-entity
              geometry="primitive: plane; height: 0.1; width: 0.1"
              position="0 -0.3 -1"
              material="color: gray; opacity: 0.5"
            >
              <a-text
                id = "distance-label"
                position="0 -0.3 -1"
                value="test text"
                rotation="0 0 0"
                scale= "1"
                width = "0.5"
                wrapCount = "10"
                align="center"
              >
              </a-text>
            </a-entity>
            <a-entity
              geometry="primitive: box; width: 0.1; height: 0.1; depth: 0.3"
              material="color: green"
              position="0 0 -1"
              look-at="#location-pointer">
            </a-entity>
            {console.log("rendered green arrow pointer")}
            {/*<a-text
              id = "distance-label"
              position="0 -0.3 -1"
              value="test text"
              rotation="0 0 0"
              align="center"
            >
            </a-text>*/}
          </a-camera>
          <a-entity
            id= "location-pointer"
            geometry="primitive: cone; radiusBottom: 0.1; radiusTop: 1; height: 2;"
            material="color: red; side: double"
            //gps-entity-place="latitude: 37.3687643668858; longitude: -122.06355101711125;"
          >
          </a-entity>
          {console.log("rendered location pointer")}
          {/*<a-entity
          id = "location-pillar"
          geometry="primitive: cylinder; height: 15; radius: 2"
          material="color: yellow; transparent: true; opacity: 0.5"
          >
          </a-entity>*/}
          {console.log("rendered location pillar")}
          {console.log("finished render loop")}
        </a-scene>
      );
    }
}

export default ARTest;
