import React from 'react'
import ContactSection from '../../ContactSection';
import Footer from '../Footer/Footer'
import {contactObj} from './Data'

function ContactPage() {
  return (
    <div>
      <ContactSection {...contactObj}/>
    </div>
  );
}

export default ContactPage
