import React from 'react'
import {Button} from './Button'
import {Link} from 'react-router-dom'
import './SeeARSection.css'

class SeeARSection extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            locationURL: this.props.locationURL
          };
    }

    componentWillReceiveProps(nextProps) {
        this.setState({ locationURL: nextProps.locationURL });  
    }

    render() {
        return (
            <>
                <div 
                    id= {this.props.id ? this.props.id : ''}
                    className= {this.props.lightBg ? 'home__hero-section' : 'home__hero-section darkBg'}>
                    <div className="container">
                        <div className="row home__hero-row"
                            style={{display: 'flex', flexDirection: this.props.imgStart === 'start' ? 'row-reverse' : 'row'}}
                        >
                            <div className="col">
                                <div className="home__hero-text-wrapper">
                                    <div className="top-line">
                                        {this.props.topLine}
                                    </div>
                                    <h1 className={this.props.lightText ? 'heading' : 'heading dark'}>{this.props.headline}</h1>
                                    <p className={this.props.lightTextDesc ? 'home__hero-subtitle': 'home__hero-subtitle dark'}> {this.props.description}</p>
                                    {
                                        this.state.locationURL ?
                                            <Link to={this.state.locationURL}>
                                            <Button buttonSize='btn--wide' buttonColor='blue'>{this.props.buttonLabel}</Button>
                                            </Link>  
                                        :
                                            <Link to="/error">
                                                <Button buttonSize='btn--wide' buttonColor='blue'>{this.props.buttonLabel}</Button>
                                            </Link> 
                                    }                                 
                                </div>
                            </div>
                            <div className ="col">
                                <div className="home__hero-img-wrapper">
                                    <img src={this.props.img} alt={this.props.alt} className="home__see-img"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default SeeARSection;