import React from 'react';

const Error = () => {
    return (
       <div>
          <h1>Oops! Page not found! If you're trying to see a location, that location may not exist.</h1>
       </div>
    );
}

export default Error;
