export const privacyObj = {
    lightBg: true,
    lightText: false,
    lightTextDesc: false,
    topLine: 'Privacy Policy',
    headline: 'We only capture your location temporarily, so you can share on your terms, and do not access it',
    description:
      "We do not collect, use, save, or have access to any of your other personal data through the Location FindAR app.",
    buttonLabel: 'Contact us',
    imgStart: '',
    img: 'images/step_3_icon_placeholder.svg',
    alt: 'Location FindAR creates a link with an AR marker to your location'
  };