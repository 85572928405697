import React, { useState , useEffect } from 'react'
import { Link } from 'react-router-dom'
import { MdFingerprint } from 'react-icons/md'
import { FaBars, FaTimes, FaLocationArrow } from 'react-icons/fa'
import { Button } from './Button'
import './Navbar.css';
import { IconContext } from 'react-icons/lib';
import { NavHashLink } from 'react-router-hash-link';

function Navbar() {
    const [click, setClick] = useState (false);
    const [button, setButton] = useState(true);
    
    const handleClick = () => setClick(!click);
    const closeMobileMenu = () => setClick(false);

    const showButton = () => {
        if(window.innerWidth <= 960) {
            setButton(false)
        } else {
            setButton(true)
        }
    }

    useEffect(() => {
        showButton();
    }, []);

    window.addEventListener('resize', showButton);

    return (
        <>
            <IconContext.Provider value = {{ color: '#fff'}}>
                <div className = "navbar">
                    <div className="navbar-container container">
                        <div className='menu-icon' onClick = {handleClick}>
                            {click ? <FaTimes/>:<FaBars />}
                        </div>
                        <Link to='/' className="navbar-logo" onClick= {closeMobileMenu}>
                            Location FindAR&nbsp;
                            <FaLocationArrow className='navbar-icon' />
                        </Link>
                        
                        <ul className= {click ? 'nav-menu active' : 'nav-menu'}>
                            <li className="nav-item">
                                <Link to='/' className="nav-links" onClick= {closeMobileMenu}>
                                    Home
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link to='/about' className="nav-links" onClick= {closeMobileMenu}>
                                    About
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link to='/support' className="nav-links" onClick= {closeMobileMenu}>
                                    Support
                                </Link>
                            </li>
                            <li className="nav-btn">
                                {button ? (
                                    <NavHashLink smooth to= '/#step1' className="btn-link">
                                        <Button buttonStyle = 'btn--outline'>GET STARTED</Button>
                                    </NavHashLink>
                                ): (
                                    <NavHashLink smooth to= '/#step1' className="btn-link" onClick= {closeMobileMenu}>
                                        <Button buttonStyle = 'btn--outline'
                                            buttonSize='btn--mobile'
                                        >GET STARTED</Button>
                                    </NavHashLink>
                                )}
                            </li>
                        </ul>
                    </div>
                </div>
            </IconContext.Provider>
        </>
    )
}

export default Navbar
