export const homeObjOne = {
    id: 'hero',
    lightBg: false,
    lightText: true,
    lightTextDesc: true,
    topLine: '',
    headline: 'Help others find you with Augmented Reality',
    description: 'Just two simple steps. First, get your location and we\'ll create a link to see it in AR. Then share the link with friends however you choose.',
    buttonLabel: 'Get started',
    imgStart: '',
    img: 'images/hero_example.png',
    alt: 'Woman holding a phone showing an arrow to a location at a park'
}

export const homeObjTwo = {
    id: 'step1',
    lightBg: true,
    lightText: false,
    lightTextDesc: false,
    topLine: 'STEP 1',
    headline: 'Get your current location',
    description:
      'Get your current location. We\'ll create a link to see an AR marker pointing to it. You can then share it or check it out yourself.',
    buttonLabel: 'Get current location',
    imgStart: '',
    img: 'images/step_1_map_placeholder.png',
    alt: 'Your GPS location'
  };
  
  export const homeObjThree = {
    id: 'step2',
    lightBg: false,
    lightText: true,
    lightTextDesc: true,
    topLine: 'STEP 2',
    headline: 'Share a link to your location',
    description:
      "Share your link however you choose, using any messaging platform you prefer. Or scroll down to see what it looks like.",
    buttonLabel: 'Share now',
    imgStart: '',
    img: 'images/step_2_share_example.png',
    alt: 'A text message with one person asking for help with a location, and the other sending a link'
  };
  
  export const homeObjFour = {
    id: 'step3',
    lightBg: true,
    lightText: false,
    lightTextDesc: false,
    topLine: 'STEP 3',
    headline: 'Try out how your location looks',
    description:
      'Take a look to see how your location appears in AR! It works best in Safari on iOS and Chrome on Android, and for the best accuracy, try it outside!',
    buttonLabel: 'See it in AR',
    imgStart: '',
    img: 'images/step_3_icon_placeholder.svg',
    alt: 'An arrow pointing to a location marker'
  };