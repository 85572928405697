import React from 'react';
import { render } from "react-dom";
import '../App.css';
import firebase from '../firebase';
import { BrowserRouter as Router, Link, Switch, Route } from 'react-router-dom';

//import sharebutton to support easier link sharing
import ShareButton from 'react-web-share-button';

//import necessities for Google Maps API
//import { Map, GoogleApiWrapper, Marker  } from 'google-maps-react';
import {GOOGLE_API_KEY} from '../config';
//import { GeoPoint } from '@google-cloud/firestore';

import useWebShare from 'react-use-web-share';
import Navbar from './Navbar';

const mapStyles = {
  width: '100%',
  height: '100%'
};

var location_id = null;
//const share = useWebShare();

class Home extends React.Component {

  constructor(props) {

    super(props);
    var location_id = null;
    this.state = {
      latitude: null,
      longitude: null,
      altitude: null,
      userAddress: null,
      locationURL: null,
      location_id: null
    };
    this.getLocation = this.getLocation.bind(this);
    this.getCoordinates = this.getCoordinates.bind(this);
    this.reverseGeocodeCoordinates = this.reverseGeocodeCoordinates.bind(this);
    this.postLocation = this.postLocation.bind(this);
    this.shareLocation = this.shareLocation.bind(this);
  }

  getLocation() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(this.getCoordinates, this.handleLocationError);
    } else {
      alert("Geolocation is not supported by this browser.");
    }
  }

  postLocation() {
    if (this.state.latitude && this.state.longitude) {
      const db = firebase.firestore()
      db.collection('test_locations').add({_latitude: this.state.latitude, _longitude: this.state.longitude, _altitude: this.state.altitude})
        .then((snapshot) => {
          console.log(snapshot.id)
          this.setState({
            location_id: snapshot.id,
            locationURL: '/find/'+snapshot.id
          });
          console.log(this.locationURL)
        })
    } else {
      alert("Latitude and Longitude not yet detected");
    }
  }

  getCoordinates(position){
    console.log(`https://maps.googleapis.com/maps/api/staticmap?center=${this.state.latitude},${this.state.longitude}&zoom=20&size=300x225&sensor=false&markers=color:red%7C${this.state.latitude},${this.state.longitude}&key=${GOOGLE_API_KEY}`);
    console.log("altitude "+ position.coords.altitude);
    this.setState({
      latitude: position.coords.latitude,
      longitude: position.coords.longitude,
      altitude: position.coords.altitude
    })
    this.reverseGeocodeCoordinates()
  }

  reverseGeocodeCoordinates(){
    fetch(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${this.state.latitude},${this.state.longitude}&sensor=false&key=${GOOGLE_API_KEY}`)
      .then(response => response.json())
      .then(data => this.setState({
        userAddress: data.results[0].formatted_address
      }))
      .catch(error => alert(error))
  }

  handleLocationError(error){
    switch(error.code) {
      case error.PERMISSION_DENIED:
        alert("User denied the request for Geolocation.")
        break;
      case error.POSITION_UNAVAILABLE:
        alert("Location information is unavailable.")
        break;
      case error.TIMEOUT:
        alert("The request to get user location timed out.")
        break;
      case error.UNKNOWN_ERROR:
        alert("An unknown error occurred.")
        break;
      default:
        alert("An unknown error occured.")
    }
  }

  shareLocation() {
    if (navigator.share) {
      navigator.geolocation.getCurrentPosition(this.getCoordinates, this.handleLocationError);
      navigator.share({
        title: "Location FindAR",
        text: "To help find my location, see it in Augmented Reality:",
        url: this.state.locationURL
      })
      .then(() => console.log('Successful share'))
      .catch((error) => console.log('Error sharing', error));
    } else {
      alert("Web Share API is not supported in your browser. Please copy and paste the link directly.");
    }
  }

  render() {
    return (
      <div className = "App">
        <Router>
          <Navbar/>
          <Switch>
            <Route path='/' />
          </Switch>
          <h2>
            Welcome to Location FindAR
          </h2>
          <h4>
            Step 1 - Get your location
          </h4>
          <button onClick={this.getLocation}>Get coordinates</button>
          <p>Latitude: {this.state.latitude}</p>
          <p>Longitude: {this.state.longitude}</p>
          <p>Altitude: {this.state.altitude}</p>
          <p>Address: {this.state.userAddress}</p>
          {
            this.state.latitude && this.state.longitude ?
              <img src={`https://maps.googleapis.com/maps/api/staticmap?center=${this.state.latitude},${this.state.longitude}&zoom=19&size=250x200&sensor=false&markers=color:red%7C${this.state.latitude},${this.state.longitude}&key=${GOOGLE_API_KEY}`} alt='' />
              :
              null
          }
          <p></p>
          <h4>
            Step 2 - Get a URL to your location
          </h4>
          {
            this.state.latitude && this.state.longitude ?
              <button
                onClick={() => {
                  this.postLocation();
                }}>
                Get URL
              </button>
              :
              null
          }
          <h4>
            Step 3 - Share a link to the location with your friends
          </h4>
          {
            this.state.locationURL ?
              <div>
                <Link to={this.state.locationURL}>Link to location in AR</Link>
                <p></p>
                <button
                  onClick={() => {
                    this.shareLocation();
                  }}>
                  Share
                </button>
              </div>
              :
              null
          }
          </Router>
      </div>
    );
  }
}

export default Home;
/*
<a href="locationpass.html?location="+{this.state.location_id}>Redirect to Html page</a>
*/
