export const supportObj = {
  lightBg: true,
  lightText: false,
  lightTextDesc: false,
  topLine: 'SUPPORT',
  headline: 'Try these tips or contact us for help!',
  description:
    "1. Location FindAR works best on Safari on iOS and on Chrome on Android. Other browsers have limited support for AR.js, the web AR library we use. 2. Location accuracy depends on GPS signal. For best results, use outside, such as in a park :) \n3. If you have trouble with the AR pointers, please refresh the web page. If you have any questions, contact us below!",
  buttonLabel: 'Contact support',
  imgStart: 'start',
  img: 'images/under_construction.png',
  alt: 'Under construction'
};